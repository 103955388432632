import React from 'react';
import Layout from '../components/layout';
import { alternateLinks } from '../data/site-data';
import Hero from '../components/common/hero';
import CtaDeck from '../components/common/cta-deck';

const headMeta = {
  title: 'Bevándorlási Ügyvéd USA | Bevándorlási Ügyvédi Iroda | Pandev Law',
  description:
    'Szeretne kivándorolni az USA-ba? A Pandev Law egy bevándorlási ügyvédi iroda, amely nagy tapasztalattal rendelkezik a bevándorlási jog területén. Lépjen velünk kapcsolatba!',
  keywords:
    'immigration law firm USA,immigration lawyers,immigration law services,immigration attorney,immigration legal services',
  alternateLinks,
};

const schema = `{
  "@context": "https://schema.org/",
  "@type": "Product",
  "name": "Bevándorlási Ügyvéd USA",
  "image": "https://www.pandevlaw.com/img/statue-liberty.jpg",
  "description": "Szeretne kivándorolni az USA-ba? A Pandev Law egy bevándorlási ügyvédi iroda, amely nagy tapasztalattal rendelkezik a bevándorlási jog területén. Lépjen velünk kapcsolatba!",
  "brand": "Pandev Law",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "0",
    "ratingCount": "246"
  }
}`;

function Hungarian({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema} lang="hu-HU">
      <Hero title="Az Utazás Amerikába Itt Kezdődik" isTitleBgTransparent hideContactLink>
        <div className="text-bg-white">
          <p className="text-lead mb-5 mb-md-8">
            A <strong>Pandev Law</strong> Ügyvédi Iroda rendkivül magas szinvonalú
            szolgáltatást nyujt a bevándorlási és üzleti jog területén. Mottónk, &ldquo;Az
            utazás Amerikába itt kezdődik&rdquo; magaért beszél.
            <br />
            <br />
            Segitséget nyújtunk nemzetközi vállalatoknak és magánszemélyeknek az Amerikai
            Egyesült Államok területére, piacára történő sikeres betöréshez. Egyedülálló
            helyzetben vagyunk az egyéni és vállalati ügyfelek hatékony képviseletére az
            Egyesült Államokban és világszerte.
          </p>
        </div>
      </Hero>

      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <p className="text-body">
              Bevándorlási gyakorlatom a befektetőkre és a munkaválaláson alapuló
              bevándorlásra összpontosít:különös tekintettel az EB-5 és az E-2 Befektetői,
              a H-1B Speciális Munkavállalói, és az L-1 Nemzetközi Vállalatközi Mozgásra
              alapuló vizumokra. Szintén segítek magánszemélyeknek családegyesitésben
              házasságon és más családalapú petíciókon keresztül. Nagy tapasztalattal
              rendelkezem a munkaválalási alapú zöldkártya-eljárásban, beleértve a PERM
              Munkaügyi Tanúsítvány, az EB-2 Nemzeti Erdekű Mentesités, és az EB-1
              Multinacionális Vezetők és Különleges Képességekkel Rendelkező Egyének
              számára mint például tudósok, kutatók, művészek és sportolók.
              <br />
              <br />
              Üzleti gyakorlatom a vállalkozók, kis - és középvállalkozások tanácsadására
              összpontosít:üzlet alapitás és irányitás, fúziók és felvásárlások,
              kereskedelmi ügyletek és finanszírozás beleértve a kockázati tőkével
              kapcsolatos ügyintézés. Rendszeresen segítek nemzetközi kereskedelmi
              ügyekben, és üzleti képviseletet nyújtok az Egyesült Államok piacára lépni
              kívánó vállalatoknak. Jogi képviseletet nyujtok az import és export
              szabályozásnak történő megfeleléshez illetve vámjog szabályok megsértése
              esetén.
            </p>
          </div>
          <div className="col-lg-6">
            <p className="text-body">
              A Pandev Law Ügyvédi Iroda létrehozása előtt dolgoztam az Egyesült Államok
              Igazságügyi Minisztérium Bevándorlási Peres Eljárások Irodájában,
              Washingtonban. Ebben a pozícióban mint ügyész képviseltem az Egyesült
              Államok kormányzati szerveit - beleértve az USA Külügyminisztériumát, az USA
              Vám- és Határvédelmi Szolgálatát, és az USA Állampolgársági es Bevándorlási
              Hivatalát - a szövetségi elsőfokú bíróságok és a szövetségi fellebbviteli
              bíróságok előtt az USA teljes teruletén.
              <br />
              <br />
              Ezt megelőzően dolgoztam az USA Munkaügyi Minisztériumában, az Atlantai
              Bevándorlási Bíroságon, egy nagy nemzetközi ügyvédi iroda New York-i
              központjában, egy Washingtoni befolyásos bevándorlási ügyvédi irodában, és a
              világ legnagyobb nemzetközi bevándorlási ügyvédi irodájában.
              <br />
              <br />
              Budapesten születtem és nőttem fel, magyar az anyanyelvem. Rendszeresen
              utazom Magyarországra és büszke vagyok a magyarságomra. Örömmel nyújtanék
              tanácsot Önnek.
              <br />
              <br />
              Köszönöm hogy meglátogatta honlapunkat.
              <br />
              <br />
              Ha szeretne velünk kapcsolatba lépni, küldjön e-mailt nekünk az{' '}
              <a className="link-primary" href="mailto:admin@pandevlaw.com">
                admin@pandevlaw.com
              </a>{' '}
              címre, vagy használja a kapcsolatfelvételi{' '}
              <a className="link-primary" href="/contact/">
                űrlapunkat
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <CtaDeck title="Kérj konzultációt ügyvédünktől" buttonText="Beszélj ügyvédünkkel" />
    </Layout>
  );
}

export default Hungarian;
