import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import CompanyRating from './company-rating';
import defaultImage from '../../assets/img/statue-liberty.jpg';

function Hero({
  title = '',
  children,
  isTitleBgTransparent = false,
  img = null,
  readMoreContent,
  className = '',
  hideContactLink = false,
  imgAlt = 'Immigration lawyer USA Pandev Law',
  hasTitleTrademark = '',
}) {
  const [isReadMoreActive, setReadMoreActive] = useState(false);

  return (
    <div className={className}>
      <img
        className="img-fluid d-lg-none"
        src={img || defaultImage}
        width="1000"
        height="650"
        alt={imgAlt}
      />
      <div className="section pt-5 pt-lg-13">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-12 position-relative">
              <div className="image-offset">
                <img
                  className="img-fluid d-none d-lg-block"
                  src={img || defaultImage}
                  alt={imgAlt}
                />
              </div>
            </div>
            <div className="col-lg-6 order-lg-1 z-index-2">
              {isTitleBgTransparent && (
                <h1 className={classnames('text-hero main-h1 mb-5')}>
                  {title}
                  {hasTitleTrademark && <span className="title-trademark">&reg;</span>}
                </h1>
              )}
              <div
                className={classnames('text-bg-white', {
                  'mt-lg-18': !isTitleBgTransparent,
                })}>
                {!isTitleBgTransparent && <h1 className="text-hero mb-5">{title}</h1>}
                {children}

                {readMoreContent && (
                  <>
                    <span
                      className={classnames('read-more-show link', {
                        'd-none': isReadMoreActive,
                      })}
                      onKeyUp={() => setReadMoreActive(true)}
                      role="button"
                      tabIndex="0"
                      onClick={() => setReadMoreActive(true)}>
                      Read more
                    </span>

                    <span
                      className={classnames('read-more-content', {
                        'd-none': !isReadMoreActive,
                      })}>
                      {readMoreContent}{' '}
                      <span
                        className="read-more-hide link"
                        onKeyUp={() => setReadMoreActive(false)}
                        role="button"
                        tabIndex="0"
                        onClick={() => setReadMoreActive(false)}>
                        Show less
                      </span>
                    </span>
                  </>
                )}
                {!hideContactLink && (
                  <>
                    <br />
                    <br />
                    <Link
                      to="/contact/"
                      className="btn btn-primary d-block d-md-inline-block button-cta mb-5">
                      Talk to a Lawyer
                    </Link>
                    <CompanyRating />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  isTitleBgTransparent: PropTypes.bool,
  img: PropTypes.string,
  readMoreContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hideContactLink: PropTypes.bool,
  imgAlt: PropTypes.string,
  hasTitleTrademark: PropTypes.bool,
};

export default Hero;
