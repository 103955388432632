import React from 'react';

function CompanyRating() {
  return (
    <div>
      <div className="container-raiting">
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
      </div>
      <p className="mb-5">
        <span className="text-phone hero">5.0-star rated company</span>
        <br />
        <span className="text-body text-grey text-grey">
          based on 246 customer reviews on Google
        </span>
      </p>
    </div>
  );
}

export default CompanyRating;
